<tag-input
  theme="custom-theme"
  class="email-tags"
  [(ngModel)]="emailsList"
  [modelAsStrings]="true"
  [placeholder]="placeholderText"
  [secondaryPlaceholder]="placeholderText"
  [clearOnBlur]="false"
  [addOnBlur]="true"
  [addOnPaste]="false"
  [separatorKeyCodes]="[32,188]"
  [animationDuration]="{enter: '0ms', leave: '0ms'}"
  (onTextChange)="onTagInputTextChange($event)"
  (onAdd)="onChange()"
  (onRemove)="onTagInputRemove()"
  [onAdding]="onTagInputAdding"
></tag-input>
