<div id="addStoreProductModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h3 id="storeModalTitle" class="modal-title">Add Widget</h3>
  </div>
  <div class="modal-body pb-0">
    <section id="productList" class="product-grid widget-grid">
      <div class="professional-content panel panel-default border-container">
        <ul class="panel-body template-list">
          @for (product of products; track product) {
            <li id="storeProduct"  class="template-card">
              <!-- Template Image -->
              <div class="template-image" (click)="select(product)">
                <img loading="lazy" src="{{product.imageUrl}}" class="img-responsive" alt="{{product.imageAlt || product.name}}">
                <span class="cta">Use this Widget</span>
                <span class="template-fx"></span>
              </div>
              <!-- Template Details -->
              <div class="template-details">
                <div class="template-title">
                  <span id="productName">{{product.name | slice:0:25}}</span>
                </div>
                <div class="template-button">
                  <button class="btn btn-primary btn-block btn-sm" id="{{slugify(product.name)}}" (click)="select(product); $event.stopPropagation()"><span>Add</span></button>
                </div>
              </div>
            </li>
          }
        </ul><!--panel-body-->
      </div><!--panel-->
    </section>
  </div>
  <div class="modal-footer">
    @if (_addWidgetByUrl) {
      <a id="addWidgetByUrl" class="btn btn-default pull-left" href="#" (click)="_addWidgetByUrl()">
        Add a Custom Rise Widget
      </a>
    }
  </div>
</div>
