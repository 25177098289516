<form id="confirmForm">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()" data-close="modal" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <div class="modal-body">
    <h4>Keep everyone in the know!</h4>
    <div class="plan-features">
      <p>Unlock this feature by subscribing to Rise Vision.</p>
      <p><strong>By subscribing, you’ll get access to:</strong></p>
      <ul >
        <li><streamline-icon name="checkmark"></streamline-icon>Hundreds of professionally-designed templates.</li>
        <li><streamline-icon name="checkmark"></streamline-icon>Dozens of content integrations.</li>
        <li><streamline-icon name="checkmark"></streamline-icon>Share to websites, personal computers, and more.</li>
        <li><streamline-icon name="checkmark"></streamline-icon>Free, world-class email and phone support.</li>
      </ul>
      <p>Digital signage doesn’t have to be difficult. We make it easy or your money back. 30 days risk-free.</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-6">
        <button class="btn btn-default w-100" (click)="close()">
          <span>Cancel</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button id="confirm-primary" class="btn btn-primary w-100" (click)="confirm()">
          <span>Subscribe</span>
        </button>
      </div>
    </div>
  </div>
</form>
