<ng-template #timepicker>
  <timepicker [(ngModel)]="time" hourStep="1" minuteStep="1"></timepicker>
</ng-template>
<div class="madero-style input-group rise-date-picker radio-nested-input-group mt-3">
  <input type="text"
    class="form-control"
    readonly="true"
    [ngModel]="displayTime"
    (click)="popover.toggle()"
  />
  <span class="input-group-btn">
    <button #popover="bs-popover"
      type="button"
      class="btn btn-primary dropdown-toggle"
      [popover]="timepicker"
      placement="bottom right"
      [outsideClick]="true"
      containerClass="timepicker"
    >
      <streamline-icon name="clock"></streamline-icon>
    </button>
  </span>
</div>
