<!-- Presentation Name -->
<stretchy-input class="presentation-name" [(ngModel)]="editorService.presentation.name" ngDefaultControl></stretchy-input>
<button id="deleteButton" [disabled]="!editorService.presentation.id" *requireRole="'cp ap'" (click)="confirmDelete()" class="btn-icon ml-2">
  <streamline-icon class="streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
</button>
<div class="btn-group" dropdown>
  <button id="moreSettingsButton" dropdownToggle class="btn btn-default-icon dropdown-toggle ml-2" [shown]="artboardService.designMode">
    <streamline-icon name="ellipsis" width="20" height="20"></streamline-icon>
  </button>
  <ul class="dropdown-menu" *dropdownMenu role="menu">
    <li><a id="changeTemplateButton" uiSref="apps.editor.add">New Presentation</a></li>
    <li><a id="copyPresentationButton" class="u_clickable" [attr.disabled]="!editorService.presentation.id" (click)="editorService.copyPresentation()">Duplicate</a></li>
    <li><a id="presentationPropertiesButton" class="u_clickable" [attr.disabled]="!editorService.presentation.id" (click)="editorService.openPresentationProperties()">Settings</a></li>
  </ul>
</div>
<div class="flex-row hidden-xs ml-auto">
  <button class="btn-icon" [disabled]="!artboardService.canZoomOut()" (click)="artboardService.zoomOut()">
    <i class="fa fa-minus"></i>
  </button>
  <div class="btn-group" dropdown>
    <button type="button" id="zoomSelectorButton" dropdownToggle class="form-control btn-select flex-row">
      <div class="row-entry">
        <span class="text-gray mr-auto">{{artboardService.zoomLevel*100 | number:'1.0-0' }}%</span>
        <span class="text-gray pl-2"><i class="fa fa-sort"></i></span>
      </div>
    </button>
    <ul class="dropdown-menu" *dropdownMenu role="menu">
      <li><a class="u_clickable" (click)="artboardService.zoomFit()">Fit To Screen</a></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(100)">Full Size</a></li>
      <li role="separator" class="divider"></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(20)">20%</a></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(50)">50%</a></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(80)">80%</a></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(100)">100%</a></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(130)">130%</a></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(150)">150%</a></li>
      <li><a class="u_clickable" (click)="artboardService.zoomPercent(200)">200%</a></li>
    </ul>
  </div>
  <button class="btn-icon mr-5" [disabled]="!artboardService.canZoomIn()" (click)="artboardService.zoomIn()">
    <i class="fa fa-plus"></i>
  </button>
</div>

@if (editorService.hasUnsavedChanges && editorService.hasContentEditorRole()) {
  <button id="saveAndPreviewButton" class="btn btn-default btn-toolbar u_margin-left preview-button"  (click)="editorService.saveAndPreview()" [disabled]="editorService.savingPresentation">
    Save & Preview
  </button>
}
@else {
  <button id="previewButton" class="btn btn-default btn-toolbar preview-button" (click)="editorService.preview(editorService.presentation.id)" [disabled]="!editorService.presentation.id || editorService.savingPresentation">
    Preview
  </button>
}
