import { Component, Self, Optional, NgZone } from '@angular/core';
import { NgControl } from '@angular/forms';

import { EmailsFieldBaseComponent } from './emails-field-base.component';

@Component({
  selector: 'emails-field',
  templateUrl: './emails-field.component.html',
  styleUrls: ['./emails-field.component.scss']
})
export class EmailsFieldComponent extends EmailsFieldBaseComponent {
  constructor(@Self() @Optional() public control: NgControl,
    zone: NgZone) {
    super(control, zone);
  }
}
