<div (mouseleave)="componentsService.highlightComponent(null)">
  <div (rvSortable)="sortItem($event)" appendTo=".attribute-editor" class="sortable-list">
    @for (comp of components; track comp; let key = $index) {
      <div class="attribute-row attribute-row-hover rv-sortable-item" [ngClass]="{ 'disabled': requiresUpgrade(comp) }">
        @if (isUserComponents) {
          <div class="rv-sortable-handle">
            <streamline-icon aria-label="Click and hold to drag and sort" class="streamline-component-icon" name="sortableHandle" width="14" height="14"></streamline-icon>
          </div>
        }
        <div class="attribute-desc" (mouseenter)="componentsService.highlightComponent(comp.id)">
          <streamline-icon aria-hidden="true" class="streamline-component-icon" name="{{ getComponentIcon(comp) }}" width="24" height="18"></streamline-icon>
          <a [hidden]="comp.editing" class="madero-link u_text-ellipsis" [ngClass]="{'strikethrough' : !getComponentVisibility(comp), 'danger': comp.fileMissing, 'disabled': requiresUpgrade(comp) }" (click)="getComponentVisibility(comp) && editComponent(comp, key);">{{ getDisplayName(comp) }}</a>
          @if (requiresUpgrade(comp) && isPowerBi(comp)) {
            <upgrade-notice #UpgradeNotice feature="power_bi" class="ml-3 mt-2"></upgrade-notice>
          }
          <div [shown]="comp.editing" class="mr-2 flex-grow">
            <input #ComponentName id="input-component-name-{{ key }}" class="w-100 form-control p-2 h-auto" type="text" [ngModel]="getDisplayName(comp)" (blur)="saveItemName(key, $event)" (keyup)="itemNameKeyup(key, $event)">
          </div>
        </div>
        <button class="btn btn-icon" aria-label="More options" mat-menu [matMenuTriggerFor]="playlistOptions">
          <streamline-icon class="streamline-component-icon" name="ellipsis" width="16" height="16"></streamline-icon>
        </button>
        <mat-menu #playlistOptions="matMenu" xPosition="before">
          @if (!requiresUpgrade(comp)) {
            <button class="playlist-item-rename" mat-menu-item (click)="renameComponent(key)">
              <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="pencil" width="16" height="16"></streamline-icon>
              <span>Edit Label</span>
            </button>
            @if (storageManagerService.isCanva(comp.source)) {
              <button class="playlist-item-canva" mat-menu-item (click)="editWithCanva(key)">
                <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="canva" width="16" height="16"></streamline-icon>
                <span>Edit with Canva</span>
              </button>
            }
          }
          <div [hidden]="isDataComponent(comp)">
            @if (!requiresUpgrade(comp) || !isUserComponents) {
              <button [shown]="getComponentVisibility(comp)" class="playlist-item-hide" mat-menu-item (click)="setComponentVisibility(comp, false)">
                <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="hide" width="16" height="16"></streamline-icon>
                <span>Hide</span>
              </button>
              <button [hidden]="getComponentVisibility(comp)" class="playlist-item-hide" mat-menu-item (click)="setComponentVisibility(comp, true)">
                <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="show" width="16" height="16"></streamline-icon>
                <span>Show</span>
              </button>
            }
            @if (isUserComponents) {
              <button  class="playlist-item-delete" mat-menu-item (click)="removeComponent(key)">
                <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
                <span>Delete</span>
              </button>
            }
          </div>
        </mat-menu>
      </div>
    }
  </div>
</div>
