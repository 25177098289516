
import { StatesModule, Transition } from '@uirouter/angular';

import { CanAccessApps } from '../ajs-upgraded-providers';

import { AddPaymentSourceComponent } from './components/add-payment-source/add-payment-source.component';
import { BillingComponent } from './components/billing/billing.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { UnpaidInvoicesComponent } from './components/unpaid-invoices/unpaid-invoices.component';
import { InvoiceService } from './services/invoice.service';
import { SubscriptionService } from './services/subscription.service';

import { ViewportComponent } from './viewport/viewport.component';

export const routes :StatesModule = {
  states: [
    {
      name: 'apps.billing',
      abstract: true,
      component: ViewportComponent
    },

    {
      name: 'apps.billing.home',
      url: '/billing?edit',
      component: BillingComponent,
      resolve: [
        {
          token: 'canAccessApps',
          deps: [CanAccessApps, InvoiceService],
          resolveFn: (canAccessApps, invoiceService) => {
            return canAccessApps()
              .then(() => {
                  // Clear potential error messages
                  invoiceService.init();
              });
          }
        }
      ]
    },

    {
      name: 'apps.billing.unpaid',
      url: '/billing/unpaid?:token',
      component: UnpaidInvoicesComponent,
      params: {
        forceAuth: false
      }
    },

    {
      name: 'apps.billing.subscription',
      url: '/billing/subscription/:subscriptionId',
      component: SubscriptionComponent,
      resolve: [
        {
          token: 'canAccessApps',
          deps: [Transition, CanAccessApps, SubscriptionService],
          resolveFn: (transition, canAccessApps, subscriptionService) => {
            return canAccessApps()
              .then(() => {
                subscriptionService.getSubscription(transition.params().subscriptionId, true);
              });
          }
        }
      ]
    },

    {
      name: 'apps.billing.payment',
      url: '/billing/payment/:subscriptionId',
      component: AddPaymentSourceComponent,
      resolve: [
        {
          token: 'canAccessApps',
          deps: [Transition, CanAccessApps, SubscriptionService],
          resolveFn: (transition, canAccessApps, subscriptionService) => {
            return canAccessApps()
              .then(() => {
                subscriptionService.getSubscription(transition.params().subscriptionId);
              });
          }
        }
      ]
    },

    {
      name: 'apps.billing.invoice',
      url: '/billing/invoice/:invoiceId?:token',
      component: InvoiceComponent,
      resolve: [
        {
          token: 'invoiceInfo',
          deps: [Transition, InvoiceService],
          resolveFn: (transition, invoiceService) => {
            // pass $stateParams to service as values could be blank before state is loaded
            invoiceService.getInvoice(transition.params().invoiceId, transition.params().cid, transition.params().token
            );
          }
        }
      ],
      params: {
        forceAuth: false
      }
    }

  ]
};
