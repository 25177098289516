import { StatesModule, Transition } from '@uirouter/angular';

import { CanAccessApps } from '../ajs-upgraded-providers';

import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { ScheduleAddComponent } from './components/schedule-add/schedule-add.component';
import { ScheduleDetailsComponent } from './components/schedule-details/schedule-details.component';
import { PlaylistService } from './services/playlist.service';
import { SchedulesViewportComponent } from './schedules-viewport/schedules-viewport.component';
import { ScheduleService } from './services/schedule.service';

export const routes: StatesModule = {
  states: [
    {
      name: 'apps.schedules',
      abstract: true,
      component: SchedulesViewportComponent
    },
    {
      name: 'apps.schedules.home',
      url: '/schedules',
      redirectTo: 'apps.schedules.list'
    },
    {
      name: 'apps.schedules.list',
      url: '/schedules/list',
      component: ScheduleListComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.schedules.add',
      url: '/schedules/add',
      params: {
        presentationItem: null
      },
      component: ScheduleAddComponent,
      resolve: [{
        token: 'canAccessApps',
        deps: [Transition, CanAccessApps, ScheduleService, PlaylistService],
        resolveFn: (transition, canAccessApps, scheduleService, playlistService) => {
          return canAccessApps()
            .then(() => {
              scheduleService.newSchedule();

              if (transition.params().presentationItem) {
                return playlistService.addPresentationItem(transition.params().presentationItem);
              }
          });
        }
      }]
    },
    {
      name: 'apps.schedules.details',
      url: '/schedules/details/:scheduleId',
      component: ScheduleDetailsComponent,
      resolve: [{
        token: 'scheduleInfo',
        deps: [Transition, CanAccessApps, ScheduleService],
        resolveFn: (transition, canAccessApps, scheduleService) => {
          return canAccessApps().then(() => {
            //load the schedule based on the url param
            return scheduleService.getSchedule(transition.params().scheduleId);
          });
        }
      }]
    }
  ]
};