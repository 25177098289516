import { Component } from '@angular/core';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss']
})
export class StyleGuideComponent {

  constructor(public companyStateService: CompanyStateService) { }
}
