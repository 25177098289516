<div class="workspace-sidebar-content">
  <div class="ph-properties">
    <div class="component-header aligner">
      <a id="backToListButton" class="madero-link u_clickable" (click)="placeholderService.clearPlaceholder()">
          <i class="fa fa-lg fa-angle-left arrow-icon pr-3"></i>Back
      </a>
      <streamline-icon class="streamline-component-icon" name="playlist" width="24" height="18"></streamline-icon>
      <span class="u_text-ellipsis mr-auto">Edit Placeholder</span>
    </div>

    <div class="btn-group btn-group-justified pb-3 mb-3 border-bottom" role="group">
      <button id="editPlaylistButton" class="btn w-50 btn-toggle" [ngClass]="{'active' : showPlaylist}" (click)="showPlaylist=true">Playlist</button>
      <button id="editPropertiesButton" class="btn w-50 btn-toggle" [ngClass]="{'active' : !showPlaylist}" (click)="showPlaylist=false">Properties</button>
    </div>

    <div class="ph-playlist animated fadeIn" [shown]="placeholderService.placeholder && showPlaylist">
      <placeholder-playlist class="attribute-editor"></placeholder-playlist>
    </div>

    <div class="ph-settings animated fadeIn" [shown]="placeholderService.placeholder && !showPlaylist">
      <placeholder-settings class="attribute-editor" [placeholder]="placeholderService.placeholder"></placeholder-settings>
    </div>
    <!--ph-settings-->
  </div>

  <div class="ph-list attribute-editor">
    <placeholders-list></placeholders-list>
  </div>
</div>
