import { Component, Self, Optional, NgZone } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import {filter} from 'rxjs/operators';

import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'emails-field-base',
  template: ''
})
export class EmailsFieldBaseComponent implements ControlValueAccessor {
  placeholderText = 'example1@email.com, example2@email.com';
  emailsList = [];
  // disabled;

  constructor(@Self() @Optional() public control: NgControl,
    private zone: NgZone) {
    this.control && (this.control.valueAccessor = this);
  }

  // from ControlValueAccessor
  public onChangeFn = (_: any) => {};
 
  public onTouchedFn = () => {};
 
  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
 
  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
 
  // public setDisabledState(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  // }
 
  public writeValue(obj: any): void {
    this.control.control.setErrors(null);

    this.emailsList = obj;
  }

  public onChange() {
    this.zone.run(() => {
      this.onChangeFn(this.emailsList);
    });
  }

  // tag-input functionality
  _validEmail(value?) {
    return ValidationService.EMAIL_REGEX.test(value);
  }

  _validate(email) {
    this.control.control.setErrors(null);

    if (email && !this._validEmail(email)) {
      this.control.control.setErrors({'emails': true});

      return false;
    } else if (!this.emailsList || !this.emailsList.length) {
      this.control.control.setErrors({'requireEmails': true});
    } else if (this.emailsList && this.emailsList.indexOf(email) >= 0) {
      this.control.control.setErrors({'duplicateEmails': true});
    }

    return true;
  }

  onTagInputTextChange($event) {
    this.zone.run(() => {
      this._validate($event);
    });
  }

  onTagInputRemove() {
    this.onChange();

    if (this.emailsList.length === 0) {
      this.control.control.setErrors({'requireEmails': true});
    }
  }

  _onAdding(email: any): Observable<string> {
    return of(email)
      .pipe(filter(() => {
        if (typeof email === 'object') {
          email = email.value;
        }

        return this._validEmail(email);
      }));
  }

  public onTagInputAdding = this._onAdding.bind(this);

}
