<div id="folderSelectorModal">
  <div class="modal-header">
    <button type="button" id="closeButton" class="close" (click)="dismiss()" data-dismiss="modal" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 id="title" class="modal-title">Select Destination</h4>
  </div>
  <div class="modal-body align-left storage-full">
    <files-list
      [folderPath]="folderPath"
      [isListView]="true"
      [allowUpload]="false"
      [allowBatchOperations]="false"
      [excludedFiles]="excludedFiles"
      (selectedItemsCount)="selectedItemsCount = $event"
      (filesSelected)="close($event)">
    </files-list>
  </div><!--modal-body-->

  <div class="modal-footer">
    <button id="newFolderButton" type="button" class="btn btn-default btn-toolbar pull-left" (click)="filesList.addFolder()" [disabled]="isTrashFolder">
      New Folder
    </button>

    <button id="folderSelectorCancelButton" type="button" class="btn btn-default btn-toolbar" (click)="dismiss()">
      Cancel
    </button>
    <button id="folderSelectorSaveButton" type="submit" class="btn btn-primary btn-toolbar" (click)="filesList.sendFiles()" [disabled]="selectedItemsCount < 1">
      Move
    </button>
 </div>
</div>