import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ModalService } from 'src/app/components/modals/modal.service';

import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { DisplayService } from './display.service';

import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';

import { firstValueFrom } from 'rxjs';

import { DisplayControlComponent } from '../components/display-control/display-control.component';

@Injectable({
  providedIn: 'root',
})
export class DisplayControlService {
  constructor(
    private modalService: ModalService,
    private httpClient: HttpClient,
    private displayService: DisplayService,
    private displayApiService: DisplayApiService,
  ) {}

  SCREEN_CONTROL_BUCKET = 'risevision-display-notifications';
  SCREEN_CONTROL_FILENAME = 'screen-control.txt';

  async getConfiguration(id?: string) {
    let display = this.displayService.display;
    const bucketName = this.SCREEN_CONTROL_BUCKET;
    const configUrl =
      StorageUtilsService.STORAGE_FILE_URL +
      bucketName +
      '/' +
      (id ?? display.id) +
      '/' +
      this.SCREEN_CONTROL_FILENAME;

    if (display && display.displayControl) {
      return display.displayControl;
    }

    const resp = await firstValueFrom(
      this.httpClient.get(configUrl, { responseType: 'text' }),
      { defaultValue: this.getDefaultConfiguration() }
    )
    .catch((err) => {
      console.info('Failed to get screen-control.txt file', err);
    });

    return resp && resp.toLowerCase();
  }

  _updateConfigurationByObject(display, config) {
    return Promise.all([
      this.displayService.applyFields(display, {
        displayControl: config
      }),
      this.displayApiService.uploadControlFile(display.id, config)
    ]);
  }

  updateConfigurationByObject = this._updateConfigurationByObject.bind(this);

  getDefaultConfiguration() {
    return (
      'interface=\n' +
      'serial-port=\n' +
      'serial-baud-rate=\n' +
      'serial-data-bits=\n' +
      'serial-parity=\n' +
      'serial-stop-bits=\n' +
      'serial-flow-control=\n' +
      'serial-screen-on-cmd=\n' +
      'serial-screen-off-cmd='
    );
  }

  updateConfiguration(config) {
    const display = this.displayService.display;

    return this.updateConfigurationByObject(display, config);
  }

  getConfigurationWithCecEnabled() {
    return (
      'interface=cec\n' +
      'serial-port=\n' +
      'serial-baud-rate=\n' +
      'serial-data-bits=\n' +
      'serial-parity=\n' +
      'serial-stop-bits=\n' +
      'serial-flow-control=\n' +
      'serial-screen-on-cmd=\n' +
      'serial-screen-off-cmd='
    );
  }

  getConfigurationWithRs232Enabled() {
    return (
      'interface=rs232\n' +
      'serial-port=\n' +
      'serial-baud-rate=\n' +
      'serial-data-bits=\n' +
      'serial-parity=\n' +
      'serial-stop-bits=\n' +
      'serial-flow-control=\n' +
      'serial-screen-on-cmd=\n' +
      'serial-screen-off-cmd='
    );
  }

  openDisplayControlModal(multiple?) {
    return this.modalService.showMediumModal(DisplayControlComponent, {
      initialState: {
        multiple
      }
    });
  }

  async getInterfaceType(id?: string) {
    const config = await this.getConfiguration(id);
    if (config?.includes('interface=rs232')) {
      return 'rs-232';
    } else {
      return 'hdmi-cec';
    }
  }

  async isDisplayControlEnabled(id?: string) {
    const config = await this.getConfiguration(id);

    if (config && (config.includes('interface=cec') || config.includes('interface=rs232'))) {
      return true;
    } else {
      return false;
    }
  }

  async enableDisplayControl(id?: string) {
    let display = this.displayService.display;

    const effectiveId = id ?? display?.id;
    if (!effectiveId) {
      console.error('Display ID is not provided and not found in the service');
      return;
    }

    display.id = effectiveId;
    this.updateConfigurationByObject(display, this.getConfigurationWithCecEnabled());
  }

  async disableDisplayControl(id?: string) {
    let display = this.displayService.display;

    const effectiveId = id || display?.id;
    if (!effectiveId) {
      console.error('Display ID is not provided and not found in the service');
      return;
    }

    display.id = effectiveId;
    this.updateConfigurationByObject(display, this.getDefaultConfiguration());
  }

  async toggleDisplayControl() {
    const display = this.displayService.display;
    if (!display?.id) {
      console.error('Display ID is not provided and not found in the service');
      return;
    }

    const isEnabled = await this.isDisplayControlEnabled(display.id);
    display.displayControlUpdated = true;

    if (isEnabled) {
      display.displayControl = this.getDefaultConfiguration();
    } else {
      display.displayControl = this.getConfigurationWithCecEnabled();
    }
  }

  async setDisplayControlToCec() {
    const display = this.displayService.display;
    if (!display?.id) {
      console.error('Display ID is not provided and not found in the service');
      return;
    }
    display.displayControl = this.getConfigurationWithCecEnabled();
  }

}
