
import { StatesModule, Transition } from '@uirouter/angular';
import { CanAccessApps } from '../ajs-upgraded-providers';
import { EditorService } from '../editor/services/editor.service';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { FinancialLicenseService } from './services/financial-license.service';
import { TemplateEditorService } from './services/template-editor.service';
import { ViewportComponent } from './viewport/viewport.component';

export const routes: StatesModule = {
  states: [
    {
      name: 'apps.editor.templates',
      url: '/templates',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.editor.templates.edit',
      url: '/edit/:presentationId/:productId',
      params: {
        productDetails: null,
        productId: '',
        trackingData: null
      },
      component: TemplateEditorComponent,
      resolve: [
        {
          token: 'presentationInfo',
          deps: [Transition, TemplateEditorService, FinancialLicenseService, EditorService, CanAccessApps],
          resolveFn: (transition, templateEditorService, financialLicenseService, editorService, canAccessApps) => {
            var signup = false;

            if (transition.params().presentationId === 'new' && transition.params().productId) {
              signup = true;
            }

            return canAccessApps(signup)
              .then(() => {
                if (transition.params().presentationId === 'new') {
                  if (transition.params().productDetails) {
                    return templateEditorService.addFromProduct(transition.params().productDetails, transition.params().trackingData);
                  } else {
                    return editorService.addFromProductId(transition.params().productId);
                  }
                } else if (!templateEditorService.presentation || templateEditorService.presentation.id !==
                  transition.params().presentationId) {
                  return templateEditorService.getPresentation(transition.params().presentationId);
                }
              })
              .then(() => {
                if (transition.params().presentationId === 'new') {
                  financialLicenseService.show();
                }
              });
          }
        }
      ]
    }
  ]
};
