<div class="form-group">
  <div class="flex-row">
    <div class="row-entry">
      <label class="control-label timeline-label pull-left mb-0 u_margin-right">Timeline:</label>

      <mat-checkbox [shown]="!readonly" class="mr-1" id="alwaysCheckbox" aria-labelledby="timelineAlwaysLabel" (ngModelChange)="toggleAlways($event)" [ngModel]="timeline.always"></mat-checkbox>
			<span id="timelineAlwaysLabel" class="mr-3 u_clickable" [shown]="!readonly || timeline.always" (click)="toggleAlways(!timeline.always)">Show all the time.</span>
    </div>
  </div>
  <span id="timelineLabelMadero" [ngClass]="{'madero-link u_clickable': !readonly}" (click)="openModal()" [shown]="!timeline.always" [innerHtml]="timeline.label"></span>
</div>