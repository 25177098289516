<div class="upgrade-modal-container">
  <div class="upgrade-modal-panel">
    <div class="upgrade-modal-text">
      <div class="flex-row mb-5">
        <streamline-icon name="circleArrowUp" width="16" height="16" class="rise-blue"></streamline-icon>
        <p class="font-weight-bold mb-1 ml-2">{{ cta }}</p>
      </div>
      <h3 class="font-weight-bold">{{ title }}</h3>
      <p>{{ description }}</p>
      <div class="button-row">
        <a class="btn btn-default btn-toolbar" target="_blank" [href]="helpLink">Learn More</a>
        <a class="btn btn-primary btn-toolbar" (click)="upgrade()">Upgrade Plan</a>
      </div>
    </div>
  </div>
  <div class="upgrade-modal-panel upgrade-feature-graphic" [ngClass]="background">
    @if (imageSource) {
      <div class="upgrade-feature-image">
        <img [src]="imageSource" alt="source">
      </div>
    }
    <button type="button" class="close text-white" (click)="dismiss()" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
</div>
