import { ComponentRef, Injectable, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { MessageBoxComponent } from './message-box/message-box.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ModalComponent } from './modal.component';
import { BulkEditModalComponent } from './bulk-edit-modal/bulk-edit-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: BsModalService) { }

  showMessage(title: string, message: string) {
    const initialState = {
      title,
      message
    };
    return this.showModal(MessageBoxComponent, {
      class: 'madero-style modal-sm',
      initialState
    });
  }

  confirm(title: string, message: string, confirmButton = 'Ok', cancelButton = 'Cancel', style = 'madero-style') {
    const initialState = {
      title,
      message,
      confirmButton,
      cancelButton
    };
    return this.showModal(ConfirmModalComponent, {
      class: style,
      initialState
    });
  }

  confirmDanger(title: string, message: string, confirmButton = 'Ok', cancelButton = 'Cancel') {
    const initialState = {
      confirmButtonClass: 'btn-danger',
      title,
      message,
      confirmButton,
      cancelButton
    };
    return this.showModal(ConfirmModalComponent, {
      initialState
    });
  }

  showBulkEditModal(title: string, baseModel: any, component: any): Promise<any> {
    return this.showMediumModal(BulkEditModalComponent, {
      initialState: {
        baseModel,
        title,
        component
      }
    });
  }

  showModal(component : {new (...args: any[]): ModalComponent}, config?) {
    const modalInstance = this.modalService.show(component, Object.assign({}, {
      // default madero modal size
      class: 'madero-style'
    }, config));
    return modalInstance.content.promise;
  }

  showMediumModal(component : {new (...args: any[]): ModalComponent}, config?) {
    return this.showModal(component, Object.assign({}, {
      class: 'madero-style modal-md'
    }, config));
  }

  showLargeModal(component : {new (...args: any[]): ModalComponent}, config?) {
    return this.showModal(component, Object.assign({}, {
      class: 'madero-style modal-lg'
    }, config));
  }

  showLegacyModal(component : {new (...args: any[]): ModalComponent}, config?) {
    return this.showModal(component, Object.assign({}, {
      class: ''
    }, config));
  }

}

angular.module('risevision.common.components')
  .factory('ngModalService', downgradeInjectable(ModalService));
