import { StatesModule } from '@uirouter/angular';

import { CanAccessApps } from '../ajs-upgraded-providers';

import { ViewportComponent } from './viewport/viewport.component';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';

export const routes: StatesModule = {
  states: [

    {
      name: 'apps.style-guide',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.style-guide.home',
      url: '/style-guide',
      component: StyleGuideComponent,
      resolve: [{
        token: 'canAccessApps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    }

  ]
};