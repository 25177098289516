<form rvSpinner="add-to-schedule-spinner" [rvShowSpinner]="scheduleSelectorService.loadingSchedules">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" data-dismiss="modal" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <div class="modal-body">
    <h4>Almost there!</h4>
    <p class="text-left mb-3 mt-3">Do you want to select or add a new schedule for this presentation?</p>

    <schedule-selector additionalTooltipClass="tooltip-sm max-z-index"></schedule-selector>

  </div>
  <div class="modal-footer">
    <button id="confirm-primary" class="btn btn-primary btn-block" (click)="ok()">
      <span>Add</span>
    </button>
  </div>
</form>
