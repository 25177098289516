<form id="companyIdModal" (ngSubmit)="apply()">
  <div class="modal-header">
    <button id="dismissButton" type="button" class="close" (click)="dismiss()">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">{{ title || 'Enter Company ID' }}</h4>
  </div>
  <div class="modal-body align-left">
    <p class="mb-4">{{ description || 'Apply action to the following Company ID.' }}</p>
    <div class="mb-4">
      <p class="mb-2"><strong>Company ID:</strong></p>
      <input type="text" class="w-100 form-control" [(ngModel)]="companyId" id="inputCompanyId" name="inputCompanyId" [focusMe]="true">
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-6">
        <button type="button" id="companyIdCancelButton" class="btn btn-default w-100" (click)="dismiss()">
          <span>{{ cancelButton || 'Cancel' }}</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button type="submit" id="companyIdApplyButton" class="btn btn-primary w-100" [disabled]="!companyId">
          <span>{{ applyButton || 'Apply' }}</span>
        </button>
      </div>
    </div>
  </div>
</form>