<form>
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" data-dismiss="modal" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <div class="modal-body">
    <h4>Assign Schedule</h4>

    <preview-selector [(selectedItem)]="selectedSchedule" additionalClass="mb-2" additionalTooltipClass="tooltip-sm max-z-index"></preview-selector>

  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-6">
        <button class="btn btn-default w-100" (click)="dismiss()">
          <span>Cancel</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button id="confirm-primary" class="btn btn-primary w-100" (click)="assign()" [disabled]="!selectedSchedule">
          <span>Assign</span>
        </button>
      </div>
    </div>
  </div>
</form>
