<div id="configure-control-modal">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Configure Display Control</h4>
  </div>
  <div class="modal-body">
    <a class="info-link madero-link" href="https://help.risevision.com/hc/en-us/articles/115005727826" target="_blank">
      <i class="fa fa-question-circle icon-left" aria-hidden="true"></i>Need help configuring Display Control?
    </a>

    <textarea class="form-control u_margin-md-top" rows="12" [(ngModel)]="formData.displayControlContents"></textarea>

    <a id="resetButton" class="madero-link" href="#" (click)="resetForm()">
      Reset To Defaults
    </a>
  </div>

  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-6">
        <button type="button" id="copyPresentationCancelButton" class="btn btn-default w-100" (click)="dismiss()">
          <span>Cancel</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button id="saveButton" type="submit" class="btn btn-primary w-100"
                (click)="saveConfiguration()" [disabled]="loading" *requireRole="'da'"
                rvSpinner="saving-display-control" [rvShowSpinner]="loading">
          {{ loading ? 'Saving' : 'Save'}}
        </button>
      </div>
    </div>
  </div>
</div>
