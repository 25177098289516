<div class="branding-colors-container attribute-list-container">

    <label class="control-label u_margin-md-top" for="branding-base-color">Base Color:</label>
    <p>Pick a dark color.</p>
    <color-picker pickerId="branding-base-color" [(ngModel)]="brandingFactory.brandingSettings.baseColor" (ngChange)="saveBranding()" ngDefaultControl></color-picker>

    <label class="control-label u_margin-md-top" for="branding-accent-color">Accent Color:</label>
    <p>Pick a light color.</p>
    <color-picker pickerId="branding-accent-color" [(ngModel)]="brandingFactory.brandingSettings.accentColor" (ngChange)="saveBranding()" ngDefaultControl></color-picker>
</div>
