<div id="timelineModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Edit Timeline</h4>
   </div>

  <div class="modal-body align-left">
    <form role="form" #timelineForm="ngForm" id="forms.timelineForm" name="forms.timelineForm">
      <div class="timeline">

        <section>
          <pick-time-range [(allDay)]="timeline.allDay" [(startTime)]="timeline.startTime" [(endTime)]="timeline.endTime" [isUTC]="true"></pick-time-range>
        </section>

        <section>
          <div class="flex-row">
            <mat-checkbox class="mr-1" name="everyDayCheckbox" id="everyDayCheckbox" [ngModel]="timeline.everyDay" (ngModelChange)="timeline.everyDay = $event" />
            <span class="control-label u_clickable" (click)="timeline.everyDay = !timeline.everyDay">Every Day</span>
          </div>

          <weekday-checkboxes [hidden]="timeline.everyDay" [(days)]="recurrence.weekly"></weekday-checkboxes>

        </section>
      </div><!--timeline-->
    </form>
  </div><!--modal-body-->

  <div class="modal-footer">
    <button type="button" id="timelineModalCancel" class="btn btn-default btn-toolbar" (click)="dismiss()">Cancel</button>
    <button type="button"
      id="timelineModalApply"
      class="btn btn-primary btn-toolbar"
      (click)="apply()"
      [disabled]="!timelineForm.valid">
      Apply
    </button>
  </div>
</div>