<div id="storageSelectorModal">
  <div class="modal-header text-center">
    <button type="button" id="closeButton" class="close" (click)="dismiss()">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 id="title" class="modal-title">{{ title }}</h4>
  </div>

  <files-list class="modal-body storage-full text-left"
    [allowBatchOperations]="false"
    (selectedItemsCount)="selectedItemsCount = $event"
    (filesSelected)="close($event)">
  </files-list>

  <div class="modal-footer u_padding-lg-horizontal">
    <button type="button" type="button" id="byURLButton" title="select" class="btn btn-primary pull-left btn-toolbar" [shown]="enableByURL" (click)="selectByUrl()">
      By URL
    </button>
    <button type="button" class="btn btn-default btn-toolbar" (click)="dismiss()" [shown]="enableByURL">
      Cancel
    </button>
    <button type="submit" id="selectFilesButton" title="select" class="btn btn-primary btn-toolbar" (click)="filesList.sendFiles()" [disabled]="selectedItemsCount < 1">
      Select
    </button>
  </div>
</div>