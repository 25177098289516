<div rvSpinner="registration-loader" [rvShowSpinner]="registrationService.loading">
  @if (unexpectedGoogleAccount) {
    <div>
      <div>
        <div class="panel-body">
          <h4>Oops! We don’t recognize your Google Account.</h4>
          <p>You can only activate your account using the email address that was invited. Please go back and activate your account using <strong>{{joinaccountState.email}}</strong>.</p>
          <div class="row mt-5">
            <div class="col-xs-12">
              <button class="btn btn-primary w-100" id="change-account-link" sign-out-button>Change Account</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  @if (unconfirmedGoogleAccount && !isSignUp) {
    <div>
      <div>
        <div class="panel-body">
          <h4>Oops! We don’t recognize your Google Account.</h4>
          <p><strong>{{ username }}</strong> does not currently have a Rise Vision account. Would you like to create one?</p>
          <div class="row mt-5">
            <div class="col-xs-6">
              <button class="btn btn-default w-100" (click)="confirmGoogleAccount()">Create a New Account</button>
            </div>
            <div class="col-xs-6">
              <button class="btn btn-primary w-100" id="try-again-link" sign-out-button>Change Account</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  @if ((!unconfirmedGoogleAccount || isSignUp) && !unexpectedGoogleAccount) {
    <div id="registration-modal">
      <form #registrationForm="ngForm" id="forms.registrationForm" role="form" name="forms.registrationForm" autocomplete="on" novalidate>
        @if (showPersonalization) {
          <h1>Help us personalize your experience.</h1>
        }
        @else {
          <h1>One last step!</h1>
        }
        <div class="panel-body">
          <div class="row u_margin-md-bottom">
            <div class="col-xs-12">
              <section ngModelGroup="personalizationModelGroup" #personalizationModelGroup="ngModelGroup" [hidden]="personalizeComplete">
                <!-- First Name -->
                <div class="form-group" [ngClass]="{ 'has-error' : firstName.invalid && !firstName.pristine }">
                  <label for="firstName" class="control-label">First Name: *</label>
                  <input #firstName="ngModel" type="text" class="form-control firstName" name="firstName" id="firstName" autocomplete="fname" aria-required="true" tabindex="1" [(ngModel)]="registrationService.profile.firstName" autofocus required>
                  <p [shown]="firstName.invalid && !firstName.pristine"
                    class="help-block validation-error-message-first-name">Enter a First Name.</p>
                </div>
                <!-- Last Name -->
                <div class="form-group" [ngClass]="{ 'has-error' : lastName.invalid && !lastName.pristine }">
                  <label for="lastName" class="control-label">Last Name: *</label>
                  <input #lastName="ngModel" type="text" class="form-control lastName" name="lastName" id="lastName" autocomplete="lname" aria-required="true" tabindex="1" [(ngModel)]="registrationService.profile.lastName" required>
                  <p [shown]="lastName.invalid && !lastName.pristine"
                    class="help-block validation-error-message-last-name">Enter a Last Name.</p>
                </div>
                @if (registrationService.newUser) {
                  <!-- Organization -->
                  <div class="form-group" [ngClass]="{'has-error': companyName.invalid && !companyName.pristine}">
                    <label for="companyName" class="control-label">Organization: *</label>
                    <input #companyName="ngModel" type="text" class="form-control companyName" name="companyName" id="companyName" tabindex="1" aria-required="true" [(ngModel)]="registrationService.company.name" [required]="registrationService.newUser">
                    <p [shown]="companyName.invalid && !companyName.pristine"
                      class="help-block validation-error-message-company-name">Enter an Organization.</p>
                  </div>
                  <!-- Industry -->
                  <div class="form-group" [ngClass]="{'has-error': companyIndustry.invalid && !companyIndustry.pristine}">
                    <label for="companyIndustry" class="control-label">Tell us your Industry to help us make your displays look great: *</label>
                    <select #companyIndustry="ngModel" class="form-control selectpicker companyIndustry" name="companyIndustry" id="companyIndustry" tabindex="1" aria-required="true" [(ngModel)]="registrationService.company.companyIndustry" (ngModelChange)="onChange()" [required]="registrationService.newUser">
                      <option [value]="undefined" hidden>&lt; Select Industry &gt;</option>
                      @for (industry of COMPANY_INDUSTRY_FIELDS; track industry) {
                        <option class="companyIndustryOption"
                          value="{{industry[1]}}">{{industry[0]}}
                        </option>
                      }
                    </select>
                    <p [shown]="companyIndustry.invalid && !companyIndustry.pristine"
                      class="help-block validation-error-message-company-industry">Select an industry.</p>
                  </div>
                  <div class="madero-style alert alert-info u_margin-sm-top" [shown]="registrationService.company?.companyIndustry === 'PHILANTHROPY'">
                    <p class="u_remove-bottom">
                      <i class="fa fa-exclamation-circle icon-left"></i>
                      <span>Special pricing is available to organizations that are registered as 501(c)(3) in the US or
                        Canadian organizations that file a T3010.</span>
                    </p>
                  </div>
                  <div class="form-group" [ngClass]="{'has-error': companyRole.invalid && !companyRole.pristine}">
                    <label for="company-role" class="control-label">
                      Company Role: *
                    </label>
                    <select #companyRole="ngModel" name="companyRole" id="companyRole" class="form-control selectpicker" required [(ngModel)]="registrationService.profile.companyRole">
                      <option value="" [shown]="false">&lt; Select Role &gt;</option>
                      @for (role of companyRoles; track role) {
                        <option value="{{role[1]}}">{{role[0]}}</option>
                      }
                    </select>
                    <p [shown]="companyRole.invalid && !companyRole.pristine"
                      class="help-block validation-error-message-company-role">Select a role.</p>
                  </div>
                  @if (registrationService.profile?.companyRole === 'other') {
                    <!-- Job Title -->
                    <div class="form-group" [ngClass]="{'has-error': jobTitle.invalid && !jobTitle.pristine}">
                      <label for="jobTitle" class="control-label">Job Title:</label>
                      <input #jobTitle="ngModel" type="text" class="form-control jobTitle" name="jobTitle" id="jobTitle" tabindex="1" [(ngModel)]="registrationService.profile.settings.jobTitle">
                    </div>
                  }
                }
              </section>

              <section ngModelGroup="finalModelGroup" #finalModelGroup="ngModelGroup">
                @if (personalizeComplete && registrationService.newUser) {
                  <div class="form-group" [ngClass]="{'has-error': country.invalid && !country.pristine}">
                    <label for="country" class="control-label">
                      @if (registrationService.company?.companyIndustry !== 'PRIMARY_SECONDARY_EDUCATION') {Company HQ} Country: *
                    </label>
                    <select #country="ngModel" id="country" class="form-control selectpicker"
                      [(ngModel)]="registrationService.company.country" (change)="onChange()" required name="country">
                      <option [value]="undefined" hidden>&lt; Select Country &gt;</option>
                      @for (c of countries; track c) {
                        <option [value]="c.code" [disabled]="c.code === null">{{c.name}}</option>
                      }
                    </select>
                  </div>
                  @if (isProvinceShown) {
                    <div class="form-group" [ngClass]="{'has-error': region.invalid && !region.pristine}">
                      @if (registrationService.company.country === 'US') {
                        <label for="state" class="control-label u_ellipsis u_align-top">
                          @if (registrationService.company?.companyIndustry !== 'PRIMARY_SECONDARY_EDUCATION') {Company HQ} State: *
                        </label>
                      }
                      @else if (registrationService.company.country === 'CA') {
                        <label for="province" class="control-label u_ellipsis u_align-top">
                          @if (registrationService.company?.companyIndustry !== 'PRIMARY_SECONDARY_EDUCATION') {Company HQ} Province: *
                        </label>
                      }
                      @if (registrationService.company.country === 'CA') {
                        <select #province="ngModel" class="form-control selectpicker" [(ngModel)]="registrationService.company.province" name="province" id="province">
                          <option [value]="undefined" hidden>&lt; Select Province &gt;</option>
                          @for (c of regionsCA; track c) {
                            <option [value]="c[1]">{{c[0]}}</option>
                          }
                        </select>
                      }
                      @else if (registrationService.company.country === 'US') {
                        <select #state class="form-control selectpicker" [(ngModel)]="registrationService.company.province" name="state" id="state">
                          <option [value]="undefined" hidden>&lt; Select State &gt;</option>
                          @for (c of regionsUS; track c) {
                            <option [value]="c[1]">{{c[0]}}</option>
                          }
                        </select>
                      }
                    </div>
                  }
                  <input #region="ngModel" type="hidden" [(ngModel)]="registrationService.company.province" [required]="isProvinceShown" name="region" id="region">
                  <!-- How did you hear about Rise Vision? -->
                  <div class="form-group" [ngClass]="{'has-error': refSource.invalid && !refSource.pristine}">
                    <label for="refSource" class="control-label">How did you hear about Rise Vision? *</label>
                    <input #refSource="ngModel" type="text" class="form-control refSource" name="refSource" id="refSource" tabindex="1" required [(ngModel)]="registrationService.profile.settings.refSource">
                  </div>
                  <!-- How many displays are you planning to use? -->
                  <div class="form-group" [ngClass]="{'has-error': displayCount.invalid && !displayCount.pristine}">
                    <label for="displayCount" class="control-label">How many displays are you planning to use? *</label>
                    <input #displayCount="ngModel" type="number" class="form-control displayCount" name="displayCount" id="displayCount" tabindex="1" required [(ngModel)]="registrationService.profile.settings.displayCount">
                  </div>
                }
              </section>

              <!-- Terms of Service and Privacy -->
              <div class="form-group" [hidden]="showPersonalization">
                <div class="checkbox" [ngClass]="{ 'has-error' : accepted.invalid && !accepted.pristine }">
                  <label for="accepted">
                    <input #accepted="ngModel" type="checkbox" id="accepted" name="accepted" class="accept-terms-checkbox" [(ngModel)]="registrationService.profile.accepted" tabindex="1" required />
                    <span>
                      I accept the
                      <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360000924446-Terms-of-Service" target="_blank" tabindex="1">Software Terms of Service</a>
                      and
                      <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360000915023-Privacy-Policy" target="_blank" tabindex="1">Privacy Policy</a>. *
                    </span>
                  </label>
                  <p class="help-block validation-error-message-accepted" [shown]="accepted.invalid && !accepted.pristine">
                    You must accept the Terms and Conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          @if (showPersonalization) {
            <button id="personalizeComplete" type="submit" aria-label="Next" tabindex="1" class="btn btn-primary btn-hg btn-block registration-next-button" (click)="onPersonalizeComplete()" [disabled]="registrationService.loading">
              Next
            </button>
          }
          @else {
            <button id="createAccount" type="submit" aria-label="Create Account" tabindex="1" form="registrationForm" class="btn btn-primary btn-hg btn-block registration-save-button" (click)="save()" [disabled]="registrationService.loading">
              Create Account
            </button>
          }
        </div><!--panel-->
        <div class="align-center u_margin-md-top">
          <a class="madero-link" id="sign-out-link" href="#" sign-out-button>Sign Out</a>
        </div>
      </form>
    </div>
  }
</div>
