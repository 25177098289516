import { StatesModule, Transition } from '@uirouter/angular';
import { CanAccessApps } from '../ajs-upgraded-providers';
import { DisplayService } from './services/display.service';
import { AlertsComponent } from './components/alerts/alerts.component';

import { DisplayAddComponent } from './components/display-add/display-add.component';
import { BulkActivationComponent } from './components/bulk-activation/bulk-activation.component';
import { DisplayDetailsComponent } from './components/display-details/display-details.component';
import { DisplayListComponent } from './components/display-list/display-list.component';
import { ViewportComponent } from './viewport/viewport.component';
import { ScreenshotService } from './services/screenshot.service';
import { InstallComponent } from './components/install/install.component';
import { CompanyStateService } from '../auth/services/company-state.service';

export const routes: StatesModule = {
  states: [
    {
      name: 'apps.displays',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.displays.home',
      url: '/displays',
      redirectTo: 'apps.displays.list'
    },
    {
      name: 'apps.displays.list',
      url: '/displays/list',
      component: DisplayListComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.displays.change',
      url: '/displays/change/:displayId/:companyId',
      resolve: [{
        token: 'canAccessAps',
        deps: [Transition, CanAccessApps, CompanyStateService],
        resolveFn: (transition, canAccessApps, companyStateService) => {
          var companyChangeRequired = companyStateService.getSelectedCompanyId() !== transition.params().companyId;

          return canAccessApps().then(function () {
              if (companyChangeRequired) {
                return companyStateService.switchCompany(transition.params().companyId);
              }
            })
            .then(function () {
              transition.abort();
              return transition.router.stateService.go('apps.displays.details', {
                displayId: transition.params().displayId,
                cid: transition.params().companyId
              });
            });
        }
      }]
    },
    {
      name: 'apps.displays.add',
      url: '/displays/add',
      params: {
        schedule: null
      },
      component: DisplayAddComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [Transition, CanAccessApps, DisplayService, ScreenshotService],
        resolveFn: (transition, canAccessApps, displayService, screenshotService) => {
          return canAccessApps().then(function () {
            displayService.newDisplay();
            screenshotService.screenshot = {};

            if (transition.params().schedule) {
              displayService.setAssignedSchedule(transition.params().schedule);
            }
          });
        }
      }]
    },
    {
      name: 'apps.displays.alerts',
      url: '/alerts',
      component: AlertsComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.displays.bulk-activation',
      url: '/bulk-activation',
      component: BulkActivationComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.displays.activate',
      url: '/activate/:activationKey',
      params: {
        activationKey: ''
      },
      component: DisplayAddComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [Transition, CanAccessApps, DisplayService, ScreenshotService],
        resolveFn: (transition, canAccessApps, displayService, screenshotService) => {
          return canAccessApps().then(function () {
            displayService.newDisplay();
            displayService.display.activationKey = transition.params().activationKey;
            screenshotService.screenshot = {};
          });
        }
      }]
    },
    {
      name: 'apps.displays.install',
      url: '/install',
      component: InstallComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.displays.details',
      url: '/displays/details/:displayId',
      params: {
        activated: false
      },
      component: DisplayDetailsComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    }
  ]
};
