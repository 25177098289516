<div id="shared-schedule-popover" class="shared-schedule-popover">
  <div class="row">
    <div class="col-xs-12">
      <button type="button" class="close" (click)="dismiss()" aria-hidden="true">
        <streamline-icon name="close" width="10" height="10"></streamline-icon>
      </button>
      <span class="text-sm font-weight-bold" [shown]="currentTab === 'link'" (click)="$event.stopPropagation()">Share</span>
    </div>
  </div>

  <div [shown]="currentTab === 'link'" class="row u_margin-sm-top" (click)="$event.stopPropagation()">
    <div class="col-xs-12 aligner">
      <input #linkText class="form-control copy-text-box w-100" type="text" readonly="readonly" [value]="getLink()" (focus)="onTextFocus($event)" />
      <button type="button" id="copyUrlButton" class="btn btn-primary ml-2" (click)="copyToClipboard(getLink())" aria-label="Copy">Copy</button>
    </div>
  </div>

  <div [shown]="scheduleService.hasInsecureUrls(scheduleService.schedule)" class="alert alert-danger text-md text-danger p-4 mt-3 mb-0" role="alert" (click)="$event.stopPropagation()">
    <p [shown]="!isScheduleDetails">
      Some content in this schedule won't be shown.
      <br/>
      <a class="madero-link" href="#" uiSref="apps.schedules.details({ scheduleId: schedule.id, cid: schedule.companyId })">Fix this.</a>
    </p>
    <p [shown]="isScheduleDetails" class="mt-0">
      Some playlist items in your schedule are using an insecure URL (HTTP) and won't be shown. Use secure URLs (HTTPS) instead.
    </p>
  </div>

</div>