<div class="app-header u_padding-20-vertical mb-5 border-bottom">
  <!-- App Title -->
  <div class="app-header-title">
    <h1>Rise Vision Player Installation</h1>
    <p><strong>Install Rise Vision Player</strong> on your existing media player and follow the <span class="u_nowrap">instructions on screen.</span></p>
  </div>
</div>

<div class="install-grid grid grid-xs-col-2 grid-md-col-4 grid-inner-borders py-5 mb-5">
  <div class="grid-cell">
    <img alt="Windows" src="/images/install/windows.png" width="80" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/115001519126">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <a class="madero-link" target="_blank" href="https://storage.googleapis.com/install-versions.risevision.com/installer-win-64.exe">
      <streamline-icon class="pr-3" name="downloadTray" width="14" height="14"></streamline-icon>64-bit Download
    </a>
    <a class="madero-link" target="_blank" href="https://storage.googleapis.com/install-versions.risevision.com/installer-win-32.exe">
      <streamline-icon class="pr-3" name="downloadTray" width="14" height="14"></streamline-icon>32-bit Download
    </a>
  </div>
  <div class="grid-cell">
    <img alt="Ubuntu" src="/images/install/ubuntu.png" width="80" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/115001872283">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <a class="madero-link" target="_blank" href="https://storage.googleapis.com/install-versions.risevision.com/installer-lnx-64.sh">
      <streamline-icon class="pr-3" name="downloadTray" width="14" height="14"></streamline-icon>64-bit Download
    </a>
  </div>
  <div class="grid-cell">
    <img alt="Fire OS" src="/images/install/fireos.png" width="80" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/4410858095508">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <div class="text-muted install-detail">Available in App Store</div>
  </div>
  <div class="grid-cell">
    <img alt="Chrome OS" src="/images/install/chrome.png" width="80" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/360001252066">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <div class="text-muted install-detail">Available in App Store</div>
  </div>
  <div class="grid-cell">
    <img alt="Raspberry Pi" src="/images/install/raspberrypi.png" width="80" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/115001872303">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <a class="madero-link" target="_blank" href="https://storage.googleapis.com/install-versions.risevision.com/installer-lnx-arm64.sh">
      <streamline-icon class="pr-3" name="downloadTray" width="14" height="14"></streamline-icon>64-bit Download
    </a>
    <a class="madero-link" target="_blank" href="https://storage.googleapis.com/install-versions.risevision.com/installer-lnx-armv7l.sh">
      <streamline-icon class="pr-3" name="downloadTray" width="14" height="14"></streamline-icon>32-bit Download
    </a>
  </div>
  <div class="grid-cell">
    <img alt="Android" src="/images/install/android.png" width="80" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/4402330525716">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <div class="text-muted install-detail">Available in App Store</div>
  </div>
  <div class="grid-cell">
    <img alt="Apple TV" src="/images/install/appletv.png" width="80" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/14876884664596">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <div class="text-muted install-detail">Available in App Store</div>
  </div>
  <div class="grid-cell">
    <img alt="Web Player" src="/images/install/web-player.svg" width="70" height="60">
    <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Vision-Player-in-a-web-browser-">
      <streamline-icon class="pr-3" name="help" width="14" height="14"></streamline-icon>Install Instructions
    </a>
    <a class="madero-link" target="_blank" href="https://widgets.risevision.com/viewer/?type=webplayer">
      <streamline-icon class="pr-3" name="arrowDiagonalRight" width="14" height="14"></streamline-icon>Launch Web Player
    </a>
  </div>
</div>

<p>
  You can also use any display that can show a web page full screen such as collaboration
  displays used in a class or meeting room powered by
  <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/360061086311-How-do-I-use-Rise-Vision-with-Airtame-">Airtame</a>,
  <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/360062378091-How-do-I-use-Rise-Vision-with-Zoom-Room-digital-signage-">Zoom Room</a>,
  <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/360062124192-How-do-I-use-Rise-Vision-with-Mersive-s-Solstice-Digital-Signage-">Mersive's Solstice</a>,
  <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/4402127464852-How-do-I-use-Rise-Vision-with-ScreenBeam-">ScreenBeam</a>,
  <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/4402127647508-How-do-I-use-Rise-Vision-with-Vivi-">Vivi</a>, or
  <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Player-on-a-thin-client-">Thin clients</a>.
</p>
<p>
  Deploy Rise Vision through a
  <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/4402330525716">mobile device management platform</a>
  to your SMART, Promethean, Clear Touch, ViewSonic, BenQ, Clevertouch, Boxlight, Newline, Avocor, or TouchView display.
</p>

<div class="text-right py-5">
  <a href="#" class="btn btn-primary btn-toolbar" (click)="location.back()">I'm Done</a>
</div>