<div class="attribute-editor-component" rvSpinner="media-loader" [rvShowSpinner]="stockLibraryService.loading">
  <basic-uploader [uploaderId]="folderUploaderId + '-uploader'"
    [uploadManager]="storageUploadManager"
    [validType]="folderType"
    [shown]="isUploading">
  </basic-uploader>
  <basic-uploader [uploaderId]="fileUploaderId + '-uploader'"
    [uploadManager]="storageUploadManager"
    [validType]="fileType"
    [shown]="isUploading">
  </basic-uploader>
  <!-- default view -->
  <div class="attribute-editor-component" [shown]="!isUploading">
    <div class="attribute-editor-row">
      @if (stockLibrary) {
        <button
          class="btn btn-default btn-block"
          (click)="stockLibraryService.showStockLibrary('image', boundAddFile, true)"
        >
          Stock Image Library
        </button>
        <button
          class="btn btn-default btn-block mt-4"
          (click)="stockLibraryService.showStockLibrary('video', boundAddFile, true)"
        >
          Stock Video Library
        </button>
      }
      @else {
        @if (canUploadFiles()) {
          <div>
            <button id="upload-file-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
              {{ uploadFileLabel }}
            </button>
            <label id="{{fileUploaderId}}-uploader-label"
              class="btn btn-default btn-block aligner"
              for="{{fileUploaderId}}-uploader"
              [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
              >
              {{ uploadFileLabel }}
            </label>
          </div>
        }
        @if (canUploadFolder()) {
          <div class="mt-3">
            <button id="upload-folder-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
              Upload Folder
            </button>
            <label id="{{folderUploaderId}}-uploader-label"
              class="btn btn-default btn-block aligner"
              for="{{folderUploaderId}}-uploader"
              [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
              >
              Upload Folder
            </label>
          </div>
        }
        <button id="te-media-select" class="btn btn-default btn-block mt-4" [disabled]="isUploading" (click)="selectFromStorage()">
          Select from Storage
        </button>
        @if (isImage || isVideo) {
          <button
            class="btn btn-default btn-block mt-4"
            (click)="stockLibraryService.showStockLibrary(fileType, boundAddFile, true)"
            [disabled]="!featuresService.isFeatureAvailable('asset_library')"
          >
            Select from Stock
            @if (isImage) { Image }
            @else { Video }
            Library
            @if (!featuresService.isFeatureAvailable('asset_library')) {
              <upgrade-notice feature="asset_library"></upgrade-notice>
            }
          </button>
        }
      }
    </div>
  </div>
</div>
