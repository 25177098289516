import { Component, ElementRef } from '@angular/core';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { ComponentsService } from '../../services/components.service';
import { MediaSelectorService } from '../services/media-selector.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { DocumentService } from 'src/app/storage/services/document.service';
import { StockLibraryService } from '../services/stock-library.service';
import { FeaturesService } from 'src/app/components/plans/features.service';

@Component({
  selector: 'component-media-selector',
  templateUrl: './media-selector.component.html',
  styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent {

  folderUploaderId = 'media-folder-uploader';
  folderType = StorageManagerService.FILE_TYPE.FOLDER;

  fileUploaderId = 'media-file-uploader';
  fileType: string;
  singleFile: boolean;
  stockLibrary: boolean;
  uploadFileLabel: string = 'Upload Files';
  boundAddFile = this.addFile.bind(this);

  get isDocument(): boolean {
    return this.fileType === StorageManagerService.FILE_TYPE.DOCUMENT;
  }

  get isImage(): boolean {
    return this.fileType === StorageManagerService.FILE_TYPE.IMAGE;
  }

  get isVideo(): boolean {
    return this.fileType === StorageManagerService.FILE_TYPE.VIDEO;
  }

  get isFolder(): boolean {
    return this.fileType === StorageManagerService.FILE_TYPE.FOLDER;
  }

  _validExtensions: string;
  get validExtensions(): string {
    return this.isDocument ?
      StorageManagerService.SUPPORTED_DOCUMENT_TYPES :
      StorageManagerService.SUPPORTED_IMAGE_VIDEO_TYPES;
  }

  storageUploadManager;
  isUploading;

  constructor(private elementRef: ElementRef,
    private componentsService: ComponentsService,
    private mediaSelectorService: MediaSelectorService,
    private storageManagerService: StorageManagerService,
    public currentPlanService: CurrentPlanService,
    private documentService: DocumentService,
    public stockLibraryService: StockLibraryService,
    public featuresService: FeaturesService
  ) {
    componentsService.registerDirective({
      type: 'rise-media-selector',
      element: this.elementRef.nativeElement,
      show: (options?) => {
        this.fileType = options?.fileType ? options.fileType : StorageManagerService.FILE_TYPE.IMAGE_VIDEO;
        this.singleFile = options?.singleFile ? options.singleFile : false;
        this.stockLibrary = options?.stockLibrary ? options.stockLibrary : false;
        this._init();
      }
    });

    this.storageUploadManager = {
      uploadedFiles: [],
      folderPath: '',
      isSingleFileSelector: () => {
        return this.singleFile || this.isImage || this.isVideo;
      },
      onUploadStatus: (isUploading) => {
        const wasUploading = this.isUploading;
        this.isUploading = isUploading;

        if (wasUploading && !isUploading) {
          this.componentsService.showPreviousPage();

          this.mediaSelectorService.storageFilesSelected(this.storageUploadManager.uploadedFiles, this.fileType);

          this.storageUploadManager.uploadedFiles = [];
        }
      },
      addFile: (file) => {
        this.storageUploadManager.uploadedFiles.push(file);
      }
    };
  }

  _init() {
    this.uploadFileLabel = this.singleFile || this.isImage || this.isVideo ? 'Upload File' : 'Upload Files';

    this.storageManagerService.isSingleFileSelector = () => {
      return this.singleFile || !!this.mediaSelectorService.onMediaUpdate;
    };

    this.storageManagerService.onSelectHandler = (selectedItems) => {
      this.componentsService.showPreviousPage();

      this.mediaSelectorService.storageFilesSelected(selectedItems, this.fileType);

      // If a single file is selected, conversion is handled when the component is opened
      if (selectedItems.length > 1 && this.fileType === StorageManagerService.FILE_TYPE.DOCUMENT) {
        // Process in the background, handles case where files in storage have not been converted yet
        selectedItems.forEach((item) => {
          this.documentService.convertFile(item.name);
        });
      }
    };
  }

  selectFromStorage() {
    if (this.isDocument) {
      this.storageManagerService.fileType = StorageManagerService.FILE_TYPE.DOCUMENT;
    } else if (this.isImage) {
      this.storageManagerService.fileType = StorageManagerService.FILE_TYPE.IMAGE;
    } else if (this.isVideo) {
      this.storageManagerService.fileType = StorageManagerService.FILE_TYPE.VIDEO;
    } else if (this.isFolder) {
      this.storageManagerService.fileType = StorageManagerService.FILE_TYPE.FOLDER;
    } else {
      this.storageManagerService.fileType = StorageManagerService.FILE_TYPE.IMAGE_VIDEO_FOLDER;
    }

    this.componentsService.editComponent({
      type: 'rise-storage-selector'
    });
  }

  canUploadFolder() {
    return !this.isDocument && !this.isImage && !this.isVideo;
  }

  canUploadFiles() {
    return !this.isFolder;
  }

  addFile(file: File) {
    this.mediaSelectorService.storageFilesSelected([file], this.fileType);
  }

}
